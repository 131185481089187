/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-clean-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-clean-your-ears",
    "aria-label": "how to clean your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How To Clean Your Ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Hearing impaired image",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "guide-to-cleaning-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#guide-to-cleaning-your-ears",
    "aria-label": "guide to cleaning your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Guide to cleaning your ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the current thinking from leading medical professionals, there is only one real reason to clean your ears: the excessive buildup of earwax. This is known in medical circles as cerumen impaction. Symptoms include a feeling like your ear is plugged, partial loss of hearing, a pain or feeling of fullness in the ear, tinnitus, or itching and ear drainage. Earwax buildup is a normal and natural process that is necessary for the health and well-being of your ear. It also cleans itself out of your ear under normal circumstances. However, there are certain circumstances where it can build up excessively and cause ear clogging."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-is-my-ear-clogged",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-is-my-ear-clogged",
    "aria-label": "why is my ear clogged permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why is my ear clogged?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can end up with your ear clogged for a number of reasons aside from cerumen impaction. It can come about as a result of air flow blockage in the Eustachian tubes, pressure deviations in the middle ear, as well as being a consequence of sinus problems and colds. The Valsalva manoeuvre (where one holds one’s nose and blows until one’s ears pop) should serve to alleviate any clogging caused by air flow blockages and pressure differences. The application of a warm compress or steam inhalation can help alleviate ear clogging associated with cold and sinus issues. If your ear clogging does not result from one of the above, it is very likely the consequence of cerumen impaction and will require ear cleaning. Observable symptoms of ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "excessive earwax"), " buildup can include ear drainage or discharge as well as an odor coming from the affected ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-clean-your-ears-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-clean-your-ears-1",
    "aria-label": "how to clean your ears 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to clean your ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A visit to your doctor is the safest way to have excessive earwax buildup removed. If this is your first time experiencing the problem, it is advisable to seek a medical opinion relating to the causes. Your doctor can clean your ears properly using a variety of different techniques and tools, including a suction device, a cerumen spoon, forceps, or even through the use of professional ear irrigation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "home-solutions-and-remedies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#home-solutions-and-remedies",
    "aria-label": "home solutions and remedies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Home solutions and remedies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several safe and easy ways you can perform an ear cleaning yourself at home. The first is the liberal application of a damp cloth. Use a warm, damp cloth to wipe around the outside of your ear and clear away any excess or leaking ear wax. This will also help to soften the wax."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Earwax softeners"), "\nThere are a variety of drops that can be purchased from pharmacies that can serve to soften the excessive buildup of ear wax and help to alleviate the blockage. They can be made from a wide variety of different solutions including mineral oil, baby oil, saline or glycerin. Hydrogen peroxide is also a common ingredient of some earwax softeners, but it is advisable to seek medical advice before using softeners of this kind. This is because, in the circumstance where the symptoms are not caused by excessive earwax but something more serious, hydrogen peroxide can potentially exacerbate the issue."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Syringes"), "\nDon’t worry. They are not a sharp as they sound! Ear irrigation syringes usually come in a bulb shape to store fluid. They can be used to irrigate the ear using water or saline. Syringes are not an effective method when used in isolation; they must be used in combination with some kind of wax softener. The softener should be applied a minimum of 15 minutes before using the syringes. You should always use warm or body temperature water to avoid causing dizziness."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-not-to-clean-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-not-to-clean-your-ears",
    "aria-label": "how not to clean your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How NOT to clean your ears!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cleaning of the ear is a practice that should only be carried out in extreme circumstances with the right tools. The ear has an in-built self-cleaning mechanism, which means you should not have to routinely clean them. This may run counter to a practice that many have been employed throughout their lifetime."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Do NOT use cotton swabs"), "\nThe current advice most often quoted by ear specialists is not to stick anything in your ear smaller than your elbow. So, no hairpins or housekeys, please! Cotton swabs are now considered a common danger to the ear health of the population as a whole because they are so often misused. Inserting something small like a cotton swab into the ear can actually push impacted earwax deeper into the ear canal, harming the eardrum and causing a more serious blockage. They also pose a risk of perforating the ear drum when inserted too deeply. If you do wish to use cotton swabs, restrict their usage to cleaning the outside of the ear. That said, this can also be achieved with the aforementioned warm, damp cloth."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Proper use of ear irrigation"), "\nYou should not attempt to irrigate your ear with a syringe or wax softeners if you have diabetes, a lowered immune system, or you suspect you have a perforated eardrum. If you are experiencing any of these issues you should always consult your doctor before attempting any kind of home remedy."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear Candles"), "\nEar candles have repeatedly failed in studies to demonstrate any ability to remove earwax. They can also potentially exacerbate the issue if wax from the candle gets into the ear."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "stay-on-top-of-your-ear-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stay-on-top-of-your-ear-health",
    "aria-label": "stay on top of your ear health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stay on top of your ear health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear cleaning of the kind described above is usually only required in extreme circumstances and is best carried out by or at least in consultation with a medical professional. There are a number of additional ways that you can care for your ear health and reduce the likelihood of the need for medical attention. If you are a swimmer, keeping your ears dry is a good place to start. This will help prevent bacteria buildup and ear infections. Did you know that regular exercise can also have an impact on your ear health? Effective blood flow is vital for proper ear function and maintenance, making any kind of cardiovascular activity a great boon for the ears! Of course, a regular checkup is also an excellent way to stay on top of your hearing and general ear health."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
